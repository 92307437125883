/* eslint-disable max-lines */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
    selectCheckoutModalOpenStatus,
    selectExclusiveOfferHasBeenOpened,
    selectIntroductoryDiscount,
    selectPayment,
    selectPaymentModalOpenStatus,
} from 'redux/Payment/selectors';
import {
    closeModals,
    setCurrentProduct,
    setIntroductoryDiscount,
    setOpenModal,
    setPaymentScreenType,
} from 'redux/Payment/actions';

import { CHECKOUT_MODAL, PAYMENT_MODAL } from 'constants/payment/modals';
import { SCREEN_TYPE } from 'constants/analytic/analytic';

import { useCustomPaymentButton } from 'hooks/useCustomPaymentButton';
import useCurrencyData from 'hooks/useCurrencyData';

import getTimerTime from 'helpers/timer';
import {
    getDiscountForPayment,
    getIndexOfPreselectedProduct,
    getProductPaymentData,
    handleAnalyticData,
} from 'helpers/payment/paymentUtils';
import { scrollToPayment } from 'helpers/other/commonUtils';

import { CurrentProduct } from 'types/payments/payments';

import { pageInfo } from 'interfaces/Payments/payments';
import { trackHeaderClick, trackPlanClick, trackRefundClick } from 'analytics/trackers/payment';
import { trackGTM } from '../services/Analytics/trackers';

export type UsePaymentLogicProps = {
    timerKey?: string;
    productsListWithDiscount: CurrentProduct[];
    productsListWithoutDiscount: CurrentProduct[];
    pageInfo: pageInfo;
    isFirstPayment?: boolean;
    withOnlyThreeProducts?: boolean;
};

export const usePaymentLogic = ({
    timerKey = 'main_timer',
    productsListWithDiscount,
    productsListWithoutDiscount,
    pageInfo,
    isFirstPayment = false,
    withOnlyThreeProducts = false,
}: UsePaymentLogicProps) => {
    const dispatch = useDispatch();

    const timerTime = getTimerTime({ key: timerKey });

    const [discount, setDiscount] = useState(0);
    const [isTimerEnd, setIsTimerEnd] = useState(withOnlyThreeProducts ? true : timerTime?.seconds <= 0);

    const { currencySign } = useCurrencyData();

    const { currentProduct: selectedProduct, isLoading } = useSelector(selectPayment);
    const paymentModalOpenStatus = useSelector(selectPaymentModalOpenStatus);
    const checkoutModalOpenStatus = useSelector(selectCheckoutModalOpenStatus);
    const exclusiveOfferModalHasBeenOpened = useSelector(selectExclusiveOfferHasBeenOpened);
    const introductoryDiscount = Number(useSelector(selectIntroductoryDiscount));

    const handleAvailableProducts = () => {
        if (withOnlyThreeProducts) {
            return productsListWithDiscount;
        }

        return !isTimerEnd ? productsListWithDiscount : productsListWithoutDiscount;
    };

    const products = handleAvailableProducts();
    const indexOfPreselectedProduct = getIndexOfPreselectedProduct(products);
    const currentProduct = selectedProduct || products[1];

    useCustomPaymentButton();

    useEffect(() => {
        if (isFirstPayment) {
            dispatch(setIntroductoryDiscount(discount));
            dispatch(setPaymentScreenType(SCREEN_TYPE.mainPayment));
        } else {
            dispatch(setPaymentScreenType(SCREEN_TYPE.specialOffer));
        }

        return () => {
            if (isFirstPayment) {
                dispatch(setIntroductoryDiscount(discount));
            }
        };
    }, [isFirstPayment, isTimerEnd, discount]);

    useEffect(() => {
        const preselectedProduct = products.find((product) => product.is_preselected) || products[1];

        if (preselectedProduct) setPaymentPrice(preselectedProduct);

        trackGTM({
            name: 'payment_screen_load',
            type: 'pageview_event',
            data: {
                pageType: isFirstPayment ? SCREEN_TYPE.mainPayment : SCREEN_TYPE.specialOffer,
            },
        });
    }, []);

    useEffect(() => {
        const regularProduct = products.find(
            ({ period, trial }) => currentProduct?.period === period && currentProduct?.trial === trial
        );

        if (regularProduct) setPaymentPrice(regularProduct);
    }, [isTimerEnd]);

    const setPaymentPrice = (currentProduct: CurrentProduct) => {
        const disc = withOnlyThreeProducts ? 0 : getDiscountForPayment(currentProduct, pageInfo);

        setDiscount(disc);
        dispatch(setCurrentProduct(currentProduct));
    };

    const handlePlanClick = () => {
        trackPlanClick('Get my plan');
        dispatch(setOpenModal(PAYMENT_MODAL));
    };

    const handleHeaderButtonClick = (eventValue: string, id: string, isNotHeader?: boolean) => {
        isNotHeader ? trackPlanClick(eventValue) : trackHeaderClick(eventValue);
        scrollToPayment(id);
    };

    const handleCurrentProduct = (product: CurrentProduct) => {
        const productData = getProductPaymentData(product);

        setPaymentPrice(product);
        handleAnalyticData(productData?.currentPaymentType);
    };

    const paymentModalHandler = () => {
        const currentProduct = products[indexOfPreselectedProduct];

        dispatch(closeModals());
        exclusiveOfferModalHasBeenOpened && dispatch(setCurrentProduct(currentProduct));
    };

    const timerEndHandler = () => {
        setIsTimerEnd(true);
        setDiscount(0);
    };

    return {
        handlePlanClick,
        handleHeaderButtonClick,
        handleCurrentProduct,
        paymentModalCloseHandler: () => {
            withOnlyThreeProducts ? dispatch(closeModals()) : dispatch(setOpenModal(CHECKOUT_MODAL));
        },
        timerEndHandler,
        currency: currencySign,
        checkoutModalOpenStatus,
        introductoryDiscount,
        exclusiveOfferModalHasBeenOpened,
        products,
        paymentTypeData: getProductPaymentData(currentProduct),
        paymentModalHandler,
        paymentModalOpenStatus,
        discount,
        timerTime: timerTime?.seconds,
        isTimerEnd,
        currentProduct,
        refundClick: trackRefundClick,
        isLoading,
        activeCardId: currentProduct?.id,
    };
};
