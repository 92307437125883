/* eslint-disable max-lines */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { selectCountry } from 'redux/User/selectors';
import { changePaymentMethod } from 'redux/Payment/actions';
import { selectGoal } from 'redux/Onboadring/selectors';

import { PAYMENT_TYPES_CAPITALIZED } from 'constants/payment/payments';

import useCheckoutConfig from 'hooks/useCheckoutConfig';

import { translate } from 'helpers/utils';
import { getPriceFromCentsFixed } from 'helpers/payment/paymentUtils';
import isMobile from 'helpers/other/isMobile';
import canPayWithApplePay from 'helpers/other/canPayWithApplePay';
import { dateNormalizerWithoutPlan } from 'helpers/normalizer/normalizer';
import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';
import { sendCheckoutAnalyticForGTM } from '../helpers/payment/helpers';

import { CurrentProduct } from 'types/payments/payments';
import { IAnalytic } from 'types/commonInterfaces';

import { pageInfo as PageInfoType } from 'interfaces/Payments/payments';
import useCurrencyData from './useCurrencyData';

export interface useCheckoutHandlerProps {
    closeHandler: () => void;
    productInfo: CurrentProduct;
    analytic: IAnalytic;
    checkoutName: string;
    isOpen?: boolean;
    paymentType?: string;
    pageInfo?: PageInfoType;
    withApplePay?: boolean;
}

export const useCheckoutLogic = ({
    productInfo: { price, start_price, price_per_state, start_price_per_state, trial },
    analytic,
    checkoutName,
    closeHandler,
    isOpen,
    paymentType,
    pageInfo,
    withApplePay = false,
    productInfo,
}: useCheckoutHandlerProps) => {
    const dispatch = useDispatch();
    const country = useSelector(selectCountry);
    const isAndroid = Boolean(isMobile.android());
    const checkoutConfig = useCheckoutConfig(pageInfo?.config?.checkout, country);

    const isHidePayPal = !Boolean(checkoutConfig?.paymentMethod?.payPal);
    const withGooglePay = Boolean(checkoutConfig?.paymentMethod?.googlePay);
    const showBankCardFirst = Boolean(isMobile.android()) && isHidePayPal;

    const getPaymentType = () => {
        if (!isHidePayPal && paymentType && !isAndroid) return paymentType;

        if (!isHidePayPal) return PAYMENT_TYPES_CAPITALIZED.PAYPAL;

        if (withApplePay && canPayWithApplePay()) return PAYMENT_TYPES_CAPITALIZED.APPLE_PAY;

        if (withGooglePay && !isAndroid) return PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY;

        return PAYMENT_TYPES_CAPITALIZED.BANK_CARD;
    };

    const [activeOption, setActiveOption] = useState(getPaymentType);

    const goal = useSelector(selectGoal);
    const plan = translate(dateNormalizerWithoutPlan(trial));
    const { currencyName, currencySign } = useCurrencyData();

    const isTrialProduct = trial === 7;
    const isIntroDisclaimer = start_price > price;
    const fullPrice = getPriceFromCentsFixed(price);
    const personalizeFullPrice = getPriceFromCentsFixed(start_price);

    const isRegularProduct = start_price === price;
    const isDiscount = price_per_state < start_price_per_state;
    const discountPrice = (Number(personalizeFullPrice) - Number(fullPrice)).toFixed(2);
    const discountPercent = Math.round((1 - Number(fullPrice) / Number(personalizeFullPrice)) * 100);
    const pricePerDay = isDiscount
        ? getPriceFromCentsFixed(price_per_state)
        : getPriceFromCentsFixed(start_price_per_state);

    useEffect(() => {
        dispatch(changePaymentMethod(activeOption));
    }, [activeOption]);

    useEffect(() => {
        if (isOpen) {
            initPaymentMethod(activeOption);
        }
    }, [isOpen, activeOption]);

    useEffect(() => {
        if (isOpen) {
            analytic.track(checkoutName);
            analytic.track('checkout');

            sendCheckoutAnalyticForGTM(productInfo, checkoutName);
        }
    }, [isOpen]);

    const sendAnalyticData = (eventValue: string) => {
        sendAnalyticsWithEventLabel({
            url: checkoutName,
            eventValue: eventValue,
        });
    };

    const initPaymentMethod = (type: string) => {
        setActiveOption(type);
    };

    const handleChange = (type: string) => {
        if (activeOption !== type) {
            initPaymentMethod(type);

            sendAnalyticData(type);
        }
    };

    const handleOnClose = () => {
        closeHandler?.();

        sendAnalyticsWithEventLabel({
            url: `close_${checkoutName}`,
            eventValue: 'close',
        });
    };

    return {
        handleOnClose,
        handleChange,
        discountPrice,
        discountPercent,
        plan,
        isIntroDisclaimer,
        currencyName,
        currencySign,
        isTrialProduct,
        isRegularProduct,
        isDiscount,
        pricePerDay,
        personalizeFullPrice,
        fullPrice,
        activeOption,
        goal,
        isHidePayPal,
        withGooglePay,
        checkoutConfig,
        withApplePay,
        showBankCardFirst,
    };
};
