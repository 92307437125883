/* eslint-disable max-lines */
import getContentId from 'redux/sagas/payment/helpers/getContentId';

import {
    DAYS_PER_SIX_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_WEEK,
    EU_DISCLAIMER,
    longProductTitle,
    monthProductTitle,
    PAYMENT_DEFAULT_CURRENCY,
    PAYMENT_TYPES_CAPITALIZED,
    threeMothProductTitle,
    US_DISCLAIMER,
    weekProductTitle,
} from 'constants/payment/payments';
import { ZIP_CODE_REGEXP } from 'constants/general/regExps';
import { DEFAULT_COUNTRY, EU_COUNTRIES_LIST, LONG_CURRENCIES } from 'constants/general/countriesData';

import { trackGTM } from 'services/Analytics/trackers';

import { CurrentProduct, IsLatamCountryType } from 'types/payments/payments';

import { PageConfigType, PaymentMethod } from 'interfaces/Payments/payments';
import { getPriceFromCents } from './paymentUtils';
import { replaceUnderscoreToDash } from '../other/transformationUtils';

export const getFormParams = (isCustomPaymentButton: boolean, autoFocus = true) => ({
    submitButtonText: 'Continue',
    headerText: 'Enter your debit or credit card details (from merchant)',
    titleText: 'Card info (from merchant)',
    formTypeClass: 'default',
    allowSubmit: !isCustomPaymentButton,
    autoFocus,
});

export const getApplePayButtonParams = (isShowApplePayMethod: boolean) => ({
    color: 'black',
    enabled: isShowApplePayMethod,
});

export const getUpsellApplePayButtonParams = (isShowApplePayMethod: boolean) => ({
    color: 'white',
    enabled: isShowApplePayMethod,
});

export const getGooglePayButtonParams = (isShowGooglePayMethod: boolean) => ({
    type: 'plain',
    enabled: isShowGooglePayMethod,
});

export const getUpsellGooglePayButtonParams = (isShowGooglePayMethod: boolean) => ({
    color: 'white',
    type: 'plain',
    enabled: isShowGooglePayMethod,
});

export const FORM_STYLES = {
    submit_button: {
        'background-color': '#0045ff',
        'font-size': '16px',
        'font-weight': 'bold',
        ':hover': {
            'background-color': 'blue',
        },
        ':disabled': {
            'background-color': '#36373a',
        },
        '.title-icon': {
            display: 'none',
        },
    },
    card_number: {
        input: {
            background: '#F9F9FA',
            border: '1px solid #D4DAE0',
            'border-radius': '12px 12px 0 0',
            color: '#474E56',
        },
        '.label': {
            display: 'none',
        },
        '.brand-icon': {
            top: '13px',
        },
    },
    expiry_date: {
        input: {
            background: '#F9F9FA',
            border: '1px solid #D4DAE0',
            'border-radius': '0 0 12px 12px',
            color: '#474E56',
            ':focus': {
                'border-color': '#8395a7',
            },
        },
        '.label': {
            display: 'none',
        },
    },
    card_cvv: {
        input: {
            background: '#F9F9FA',
            border: '1px solid #D4DAE0',
            'border-radius': '0 0 12px 12px',
            color: '#474E56',
            ':focus': {
                'border-color': '#8395a7',
            },
        },
        '.label': {
            display: 'none',
        },
        '.tooltip-icon': {
            display: 'none',
        },
    },
    zip_code: {
        input: {
            background: '#F9F9FA',
            border: '1px solid #D4DAE0',
            'border-radius': '12px',
            color: '#474E56',
            ':focus': {
                'border-color': '#8395a7',
            },
            'margin-bottom': '20px',
        },
        '.label': {
            'font-size': '14px',
            'font-weight': '500',
            'line-height': '20px',
            'font-family': 'Roboto, sans-serif',
            color: '#474E56',
        },
        '.input-block.not-empty .label': {
            'font-size': '14px',
            'font-weight': '500',
            'line-height': '20px',
        },
        '.input-block.error .label': {
            'font-size': '14px',
            'font-weight': '500',
            'line-height': '20px',
        },
        '.tooltip-icon': {
            display: 'none',
        },
        '.error-text': {
            'margin-top': '-20px',
            'margin-bottom': '20px',
        },
    },
};

export const getDataOption = (activeOption: PaymentMethod) => {
    switch (activeOption) {
        case PAYMENT_TYPES_CAPITALIZED.APPLE_PAY:
            return 'ApplePayOption';

        case PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY:
            return 'googlePay';

        default:
            return 'card';
    }
};

export const validateZipCode = (value: string): boolean => {
    return ZIP_CODE_REGEXP.test(value);
};

export const formattingZipCode = (value: string) => {
    const NUMBER_MIN_LENGTH = 5;
    const SYMBOLS_MIN_LENGTH = 6;
    const SYMBOLS_MAX_LENGTH = 10;

    if (value.length === NUMBER_MIN_LENGTH) return value;

    const isDash = value.charAt(NUMBER_MIN_LENGTH) === '-';

    if (!isDash && value.length === SYMBOLS_MIN_LENGTH) {
        const left = value.slice(0, NUMBER_MIN_LENGTH);
        const right = value.slice(NUMBER_MIN_LENGTH);

        return `${left}-${right}`;
    }

    if (isDash && value.length > SYMBOLS_MIN_LENGTH) {
        return value.slice(0, SYMBOLS_MAX_LENGTH);
    }

    if (value.length <= SYMBOLS_MAX_LENGTH) {
        return value;
    }
};

export const formatZipCode = (value: string) => {
    const NUMBER_MIN_LENGTH = 5;
    const digitsOnly = value.replace(/\D/g, '');

    if (digitsOnly.length > NUMBER_MIN_LENGTH) {
        return `${digitsOnly.slice(0, NUMBER_MIN_LENGTH)}-${digitsOnly.slice(NUMBER_MIN_LENGTH)}`;
    }

    return digitsOnly;
};

export const getDisclaimer = (config?: PageConfigType, countryCode?: string | null) => {
    const countryIsEU = EU_COUNTRIES_LIST.some((item) => item.code === countryCode);
    const countryIsUSA = countryCode === DEFAULT_COUNTRY;

    const disclaimerMap = {
        US: config?.US || US_DISCLAIMER,
        UAE: config?.UAE || EU_DISCLAIMER,
    };

    switch (true) {
        case countryIsUSA:
            return ` / ${disclaimerMap.US}`;

        case countryIsEU:
            return ` / ${disclaimerMap.UAE}`;

        default:
            return '';
    }
};

export const isLongCurrencyVerifier: IsLatamCountryType = (currency: string) => {
    return LONG_CURRENCIES.includes(currency);
};

export const activePaymentOptionVerifier = (activeOption: string) => {
    const isApplePayOption = activeOption === PAYMENT_TYPES_CAPITALIZED.APPLE_PAY;
    const isCreditCardOption = activeOption === PAYMENT_TYPES_CAPITALIZED.BANK_CARD;
    const isPayPalOption = activeOption === PAYMENT_TYPES_CAPITALIZED.PAYPAL;
    const isOneClickPaymentOption = activeOption === PAYMENT_TYPES_CAPITALIZED.ONE_CLICK_PAYMENT;
    const isGooglePayOption = activeOption === PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY;

    return {
        isApplePayOption,
        isPayPalOption,
        isCreditCardOption,
        isOneClickPaymentOption,
        isGooglePayOption,
    };
};

export const getCardTitle = (period: number, trial: number) => {
    if (trial === DAYS_PER_WEEK) {
        return weekProductTitle;
    }

    if (period === DAYS_PER_THREE_MONTH) {
        return threeMothProductTitle;
    }

    if (period >= DAYS_PER_SIX_MONTH) {
        return longProductTitle;
    }

    return monthProductTitle;
};

export const getMinProduct = (products: CurrentProduct[]) => {
    const minPriceIndex = products.reduce((a, b, i) => (products[a].price_per_state < b.price_per_state ? a : i), 0);

    return products[minPriceIndex].price_per_state;
};

export const getDataFromCurrentProduct = (currentProduct: CurrentProduct, screenId: string) => {
    if (!currentProduct) return {};

    const searchParams = new URLSearchParams(window.location.search);

    return {
        url_params: Object.fromEntries(searchParams),
        price: getPriceFromCents(currentProduct?.start_price) || getPriceFromCents(currentProduct?.price),
        subscription_price: getPriceFromCents(currentProduct?.price) || 0,
        tariff: currentProduct?.id,
        currency: currentProduct.code || PAYMENT_DEFAULT_CURRENCY.name,
        value: currentProduct?.ltv / 100,
        screen_id: replaceUnderscoreToDash(screenId),
        content_id: getContentId({
            product_code: currentProduct.product_code,
            payment_type: currentProduct.payment_type,
            price: getPriceFromCents(currentProduct.start_price),
            period: currentProduct.period,
            trialPeriod: currentProduct.trial,
            trialPrice: getPriceFromCents(currentProduct.price),
        }),
    };
};

export const sendCheckoutAnalyticForGTM = (currentProduct: CurrentProduct, checkoutName: string) => {
    trackGTM({
        name: 'checkout_screen_load',
        type: 'pageview_event',
        data: {
            pageType: 'checkout',
            ...getDataFromCurrentProduct(currentProduct, checkoutName),
        },
    });
};
