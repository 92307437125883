/* eslint-disable max-lines */
import { Main } from 'wikr-core-analytics';
import { SENTRY_PAYMENT } from 'sentry-utils';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import { CardBrand, ClientSdkInstance, SdkMessage } from '@solidgate/react-sdk';
import { MessageType } from '@solidgate/client-sdk-loader';

import { selectUser } from 'redux/User/selectors';
import { handleErrorAction, setPaidStatus } from 'redux/User/actions';
import {
    setExclusiveOfferHasBeenOpened,
    setHideCustomPaymentButton,
    setIsApplePayAvailable,
    setIsGooglePayAvailable,
    setIsLoadingBankCard,
    setIsPaymentSuccess,
    setLoadingStatus,
    setOpenModal,
    setPaymentErrorCode,
} from 'redux/Payment/actions';

import {
    BANK_CARD_CUSTOM_BUTTON_ID,
    PAYMENT_EVENT_NAMES,
    PAYMENT_METHOD_NAME,
    SOLID_ENTITY_TO_PAYMENT_METHOD_NAME_MAP,
} from 'constants/payment/payments';
import { EXCLUSIVE_OFFER_MODAL } from 'constants/payment/modals';
import { INSUFFICIENT_FUNDS_ERROR_CODE } from 'constants/errors/errors';

import useValidatePayment from 'hooks/useValidatePayment';

import { formatPriceRelativeToCurrency } from 'helpers/payment/paymentUtils';
import getErrorCustomTags from '../helpers/payment/getPaymentErrorCustomTags';

import { IUsePaymentHandlers } from 'types/payments/payments';

export const usePaymentHandlers = ({
    paymentData,
    currentProduct,
    initPaymentGenerator,
    pageInfo,
    setHasPaymentError,
    setHasPaymentSuccess,
}: IUsePaymentHandlers) => {
    const dispatch = useDispatch();
    const [isProcessing, setIsProcessing] = useState(false);
    const cardBrandRef = useRef<CardBrand | null>(null);
    const { country } = useSelector(selectUser);

    const validatePaymentHandler = useValidatePayment({ pageInfo });

    const showExclusiveOffer = () => {
        dispatch(setOpenModal(EXCLUSIVE_OFFER_MODAL));
        dispatch(setExclusiveOfferHasBeenOpened(true));
    };

    const handleOnSubmit = (event: SdkMessage[MessageType.Submit]) => {
        const paymentValue = PAYMENT_EVENT_NAMES[event.entity];

        dispatch(setLoadingStatus(true));
        setIsProcessing(true);

        Main.customData('processor_form__click', { payment: paymentValue, event_label: paymentValue });
    };

    const handleOnSuccess = (data: SdkMessage[MessageType.Success]) => {
        dispatch(setPaidStatus(true));

        validatePaymentHandler({
            paymentMethod: SOLID_ENTITY_TO_PAYMENT_METHOD_NAME_MAP[data.entity],
            order: {
                amount: data.order.amount as number,
                order_id: data.order.order_id as string,
                subscription_id: data.order.subscription_id as string,
            },
            ...(cardBrandRef.current && { brand: cardBrandRef.current as CardBrand }),
        });

        setHasPaymentSuccess(true);

        dispatch(setIsPaymentSuccess(true));
        dispatch(setHideCustomPaymentButton(true));

        setIsProcessing(false);
    };

    const handleCard = (e: SdkMessage[MessageType.Card]) => {
        cardBrandRef.current = e.card.brand as CardBrand;
    };

    const handleOnError = (error: SdkMessage[MessageType.Error]) => {
        setIsProcessing(false);
        dispatch(setIsPaymentSuccess(false));

        cardBrandRef.current = null;

        const customTags = getErrorCustomTags({
            errorData: error,
            country: country as string,
            paymentMethod: PAYMENT_METHOD_NAME[paymentData.payment_method],
        });
        const errorCode = error?.details?.code;

        if (errorCode) {
            dispatch(
                setPaymentErrorCode({
                    error_code: errorCode,
                    product_name: currentProduct?.name,
                    currency: paymentData.currency,
                    product_price: formatPriceRelativeToCurrency(currentProduct?.price as number, paymentData.currency),
                })
            );
        }

        dispatch(
            handleErrorAction(
                error,
                SENTRY_PAYMENT,
                {
                    ...currentProduct,
                    ...paymentData,
                },
                customTags
            )
        );

        const codeErrorList = [INSUFFICIENT_FUNDS_ERROR_CODE];

        if (codeErrorList.includes(errorCode as string)) {
            setTimeout(() => {
                showExclusiveOffer();
            }, 2000);
        }

        setTimeout(() => {
            initPaymentGenerator();
        }, 5000);
    };

    const handleOnReadyPaymentInstance = (form: ClientSdkInstance) => {
        dispatch(setHideCustomPaymentButton(false));
        dispatch(setIsPaymentSuccess(false));
        dispatch(setLoadingStatus(false));

        setIsProcessing(false);

        const customPaymentButton = document.getElementById(BANK_CARD_CUSTOM_BUTTON_ID);

        if (customPaymentButton) {
            customPaymentButton.onclick = () => {
                if (isProcessing) return;

                form?.submit();
            };
        }

        Main.customData('processor_form__load', { payment: 'bank_card', event_label: 'bank_card' });
    };

    const handleOnFail = (error: SdkMessage[MessageType.Fail]) => {
        const customTags = getErrorCustomTags({
            errorData: error,
            country: country as string,
            paymentMethod: PAYMENT_METHOD_NAME[paymentData.payment_method],
        });

        const errorCode = error?.code ?? '';

        setHasPaymentError(true);

        dispatch(setHideCustomPaymentButton(true));
        dispatch(setIsPaymentSuccess(false));

        setIsProcessing(false);

        if (errorCode) {
            dispatch(
                setPaymentErrorCode({
                    error_code: errorCode,
                    product_name: currentProduct?.name,
                    currency: paymentData.currency,
                    product_price: formatPriceRelativeToCurrency(currentProduct?.price as number, paymentData.currency),
                })
            );
        }

        if (errorCode === INSUFFICIENT_FUNDS_ERROR_CODE) {
            setTimeout(() => {
                showExclusiveOffer();
            }, 2000);
        }

        dispatch(
            handleErrorAction(
                error,
                SENTRY_PAYMENT,
                {
                    ...currentProduct,
                    ...paymentData,
                },
                customTags
            )
        );

        setTimeout(() => {
            dispatch(setHideCustomPaymentButton(false));
            initPaymentGenerator();
        }, 3000);
    };

    const handleOnMounted = (event: SdkMessage[MessageType.Mounted]) => {
        if (event.type !== 'mounted') return;

        if (event.entity === 'applebtn') {
            dispatch(setIsApplePayAvailable(true));
        }

        if (event.entity === 'googlebtn') {
            dispatch(setIsGooglePayAvailable(true));
        }

        if (event.entity === 'form') {
            dispatch(setIsLoadingBankCard(false));
        }
    };

    return {
        isProcessing,
        handleOnSubmit,
        handleOnSuccess,
        handleOnError,
        handleOnFail,
        handleOnReadyPaymentInstance,
        handleOnMounted,
        handleCard,
    };
};
