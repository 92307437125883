import { Main, systemNames } from 'wikr-core-analytics';
import { hotjar } from 'react-hotjar';

import config from 'config';

import { HOTJAR_VERSION } from 'constants/general/versions';

import { getParamFromUrl } from 'helpers/other/commonUtils';
import getAmazonDefaultData from 'helpers/analytic/getAmazonDefaultData';

const HOTJAR_NUMBER_ID = +config?.HOTJAR_ID;

export const initAmazonAnalytic = () => {
    const awsId = localStorage.getItem('aws_id');

    Main.initSystems({
        activeSystems: [
            {
                name: systemNames.amazon,
                id: config.AMAZON_ID,
                config: {
                    releaseDate: config.RELEASE_DATE,
                    streamName: config.AWS_DELIVERY_STREAM_NAME,
                    envMode: config.ENV,
                    awsId,
                },
            },
        ],
    });

    getAmazonDefaultData().then((defaultData) => {
        defaultData && localStorage.setItem('aws_id', defaultData.aws_id);
    });
};

export const initAnalytics = () => {
    const currentHost = window.location.host;
    const cabinetHost = new URL(config.CABINET_URL).host;
    const welcomePageHost = new URL(config.WELCOME_PAGE_URL).host;

    const activeSystems = [
        {
            name: systemNames.ga4,
            id: config.GOOGLE_4_ID,
            config: {
                googleAdsId: config.GOOGLE_ADS_ID,
                googleAdsShopId: config.GOOGLE_ADS_SHOP_ID,
                domains: [currentHost, cabinetHost, welcomePageHost],
            },
        },
        {
            name: systemNames.twitter,
            id: config.TWITTER_ID,
        },
        {
            name: systemNames.pixel,
            id: config.PIXEL_ID,
        },
        {
            name: systemNames.tiktok,
            id: config.TIKTOK_ID,
        },
        {
            name: systemNames.snapchat,
            id: config.SNAPCHAT_ID,
        },
    ];

    if (config.PINTEREST_ID) {
        activeSystems.push({
            name: systemNames.pinterest,
            id: config.PINTEREST_ID,
        });
    }

    Main.initSystems({
        activeSystems,
    });
};

export const initGTM = () => {
    if (!config.GTM_ID) return;

    Main.initSystems({
        activeSystems: [
            {
                name: systemNames.gtm,
                id: config.GTM_ID,
            },
        ],
    });
};

export const initHotjar = () => {
    const track = getParamFromUrl('track');

    track && HOTJAR_NUMBER_ID && hotjar.initialize(HOTJAR_NUMBER_ID, HOTJAR_VERSION);
};
