export const ResultPages = [
    {
        path: 'other/ResultA',
        route: 'result-a',
    },
    {
        path: 'other/ResultConf',
        route: 'result-conf',
    },
    {
        path: 'other/ResultConfB',
        route: 'result-conf-b',
    },
    {
        path: 'other/ResultKgl',
        route: 'result-kgl',
    },
    {
        path: 'other/ResultConfC',
        route: 'result-conf-c',
    },
];
