import { WorkoutIssueValues } from 'pages/onboarding/ObWorkoutIssues/constants';
import { MotivationValues } from 'constants/onboarding/onboardingConstants';
import {
    defaultBranchName,
    defaultConsentMarketingStatus,
    defaultCurrentBody,
    defaultCurrentWeight,
    defaultDateOfBirth,
    defaultEmail,
    defaultFitnessLevel,
    defaultGender,
    defaultGoal,
    defaultLanguage,
    defaultMeasureSystem,
    defaultTall,
    defaultTargetBody,
    defaultTargetWeight,
    defaultTargetZones,
    defaultTestName,
    defaultTrainingLocations,
} from 'constants/onboarding/defaultOnboardingData';

import { OccasionList } from 'types/onboarding/onboarding';

import { UserSingUpInfo } from 'interfaces/User/user';

export const DEFAULT_USER: UserSingUpInfo = {
    branch_name: defaultBranchName,
    date_of_birth: defaultDateOfBirth,
    email: defaultEmail,
    fitness_level: defaultFitnessLevel,
    gender: defaultGender,
    goal: defaultGoal,
    height: defaultTall,
    language: defaultLanguage,
    target_bodytype: defaultTargetBody,
    current_bodytype: defaultCurrentBody,
    problem_zones: defaultTargetZones,
    consent_marketing: defaultConsentMarketingStatus,
    target_weight: defaultTargetWeight,
    test_name: defaultTestName,
    training_locations: defaultTrainingLocations,
    units: defaultMeasureSystem,
    weight: defaultCurrentWeight,
    occasion: OccasionList.no,
    motivation: [MotivationValues.improving_health],
    setbacks: [WorkoutIssueValues.motivation, WorkoutIssueValues.unclear_plan],
};

export const LTV_COEFFICIENT_KEY = 'ltv_coef';

export const DEFAULT_LTV_COEFFICIENT = 1;

export const DEFAULT_TEST_USER_PASSWORD = '123qwe';

export const GA_USER_ID = '_ga';
