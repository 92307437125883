import { Init } from 'testania';

import config from 'config';

import { defaultBranchName, defaultTestName } from 'constants/onboarding/defaultOnboardingData';
import { DEFAULT_FLOW } from 'constants/general/defaultFlow';

import { checkFbBots } from 'helpers/other/commonUtils';
import { getBranchNameFromLocalStorage, getNameFromUrl } from './helpers';

const URL_PARAMS_NAMES = {
    test: 'test-name',
    branch: 'branch-name',
    variant: 'variant-name',
};

export const TESTANIA_INIT_CONFIG: Init = {
    testName: getNameFromUrl(URL_PARAMS_NAMES.test, defaultTestName),
    branchName: getNameFromUrl(URL_PARAMS_NAMES.branch, getBranchNameFromLocalStorage() || defaultBranchName),
    variantName: getNameFromUrl(URL_PARAMS_NAMES.variant, ''),
    defaultBranches: ['fasting', 'default'],
    defaultFlow: DEFAULT_FLOW,
    testaniaUrl: config.TESTANIA_HOST,
    testaniaApiKey: config.TESTANIA_X_API_KEY,
    project: 'menscoach',
    expireConfigTime: 1440,
    runOnlyLocalConfig: checkFbBots(),
};

export const THANK_YOU_PAGE_PATH = 'thank-you';

export const DOWNLOAD_APP_PATH = 'muscle-booster-app';

export const TEST_NAMES = {
    go12kal: 'go12kal',
    go11til: 'go11til',
    go4kus: 'go4kus',
    test_new_design_flow: 'test_new_design_flow',
    go15jkl: 'go15jkl',
    go2fnk: 'go2fnk',
};

export const BRANCH_NAMES = {
    general: 'general',
    google: 'google',
    chair: 'chair',
};

export const URL_PARAMS_FOR_REDESIGN_FLOW = {
    branchName: BRANCH_NAMES.general,
    testName: TEST_NAMES.go15jkl,
};
