import { Theme } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { Dispatch, useState } from 'react';

import { setFirstPageData, setInlineTheme, setLogoUrl } from 'redux/UiEffects/actions';
import { setSandbox } from 'redux/Payment/actions';

import { getFaviconElement } from 'helpers/other/commonUtils';

import { ThemeConfigScheme } from 'types/pages/startPages/commonTypes';

export const useThemeConfiguration = (themeConfig: Record<string, ThemeConfigScheme>) => {
    const dispatch = useDispatch();

    const [theme, setTheme] = useState<Theme>();

    if (!theme) {
        const hostName = window.location.host;

        const isMirror = Object.keys(themeConfig).includes(hostName);

        localStorage.setItem('isMirror', `${isMirror}`);

        const newThemeStyles = setThemeValues(dispatch, themeConfig);

        setTheme(newThemeStyles);
    }

    return theme ?? themeConfig['default'].themeStyle;
};

export const setThemeValues = (
    dispatch: Dispatch<any>,
    themeConfig: Record<string, ThemeConfigScheme>,
    themeName = 'default'
) => {
    const hostName = window.location.host;

    const { firstPageImage, firstPageTitle, inlineStyle, themeStyle, logoUrl, faviconUrl, sandbox } =
        themeConfig?.[hostName] || themeConfig[themeName];

    const sandboxParsed = JSON.parse(sandbox);

    const faviconElement = getFaviconElement() as HTMLLinkElement;

    faviconElement.href = faviconUrl;

    dispatch(
        setFirstPageData({
            firstPageTitle,
            firstPageImage,
        })
    );
    dispatch(setLogoUrl(logoUrl));
    dispatch(setInlineTheme(inlineStyle));
    dispatch(setSandbox(sandboxParsed));

    return themeStyle;
};
