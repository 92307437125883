/* eslint-disable max-lines */
import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectUser } from 'redux/User/selectors';
import { handleErrorAction, setPaidStatus } from 'redux/User/actions';
import {
    selectIsLoading,
    selectIsLoadingBankCard,
    selectIsLoadingPayPal,
    selectPayment,
    selectValidatePaymentData,
} from 'redux/Payment/selectors';
import {
    changePaymentMethod,
    initPaymentGenerator as initPaymentGeneratorAction,
    initPayPal as initPayPalAction,
    setIsLoadingBankCard,
    setIsLoadingPayPal,
    setIsPaymentScreen,
    setLoadingStatus,
} from 'redux/Payment/actions';

import { PAYMENT_TYPES, PAYMENT_TYPES_CAPITALIZED, PAYPAL_OPTION, SOLID_TAGS } from 'constants/payment/payments';
import { MAIN_PLAN } from '../constants/product/productNames';

import useValidatePayment from 'hooks/useValidatePayment';
import useCurrencyData from 'hooks/useCurrencyData';

import { getErrorReasonGroup, preparePayPalErrorMessage } from 'helpers/payment/paymentUtils';

import { IUsePaymentLibData } from 'types/payments/payments';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { DataForValidate } from 'interfaces/Payments/paymentSystemsInit';
import { pageInfo } from 'interfaces/Payments/payments';
import { useSolidPaymentPreInit } from './useSolidPaymentPreInit';
import useCheckoutConfig from './useCheckoutConfig';

interface IUsePaymentLib {
    pageInfo: pageInfo;
    toNextPage: ({ isValid }: { isValid: boolean }) => void;
}

export const usePaymentLib = ({ toNextPage, pageInfo }: IUsePaymentLib): IUsePaymentLibData => {
    const dispatch = useDispatch();

    const { paymentMethod: paymentMethodsConfig } = useCheckoutConfig(pageInfo?.config?.checkout);

    const isLoading = useSelector(selectIsLoading);
    const isLoadingPayPal = useSelector(selectIsLoadingPayPal);
    const isLoadingBankCard = useSelector(selectIsLoadingBankCard);

    const validatePaymentData = useSelector(selectValidatePaymentData);
    const { currentProduct } = useSelector(selectPayment);
    const { user_id, country } = useSelector(selectUser);

    const paymentType = pageInfo.products[0].payment_type;

    const { currencyName } = useCurrencyData();
    const validatePayment = useValidatePayment({ pageInfo });

    useSolidPaymentPreInit({ initPayPal, initPaymentGenerator, paymentMethodsConfig });

    useEffect(() => {
        dispatch(setIsPaymentScreen(true));

        return () => {
            dispatch(setIsPaymentScreen(false));
        };
    }, []);

    useEffect(() => {
        if (validatePaymentData && Boolean(validatePaymentData?.result)) {
            toNextPage({
                isValid: validatePaymentData.result,
            });
        }
    }, [validatePaymentData]);

    function initPayPal() {
        const meta = {
            userId: user_id,
            payment_method: PAYMENT_TYPES.PAYPAL,
            currency: currencyName,
            product_code: MAIN_PLAN,
            country,
        };

        const successHandler = (data: DataForValidate) => {
            const errorCode = data?.error?.code;

            if (!errorCode) {
                dispatch(setPaidStatus(true));

                dispatch(changePaymentMethod(PAYMENT_TYPES_CAPITALIZED.PAYPAL));

                validatePayment({
                    paymentMethod: PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                    order: {
                        amount: data.order.amount as number,
                        order_id: data.order.order_id as string,
                        subscription_id: data.order.subscription_id as string,
                    },
                });

                return;
            }

            dispatch(setLoadingStatus(false));
            initPayPal();

            dispatch(
                handleErrorAction(
                    preparePayPalErrorMessage(data.error),
                    SENTRY_PAYMENT,
                    {
                        ...currentProduct,
                        ...meta,
                    },
                    [
                        [SOLID_TAGS.SOLID_PAYMENT_METHOD, PAYPAL_OPTION],
                        [SOLID_TAGS.SOLID_ERROR_CODE, errorCode],
                        [SOLID_TAGS.SOLID_ERROR_MESSAGE, data?.error?.recommended_message_for_user || ''],
                        [SOLID_TAGS.SOLID_ERROR_REASON, getErrorReasonGroup(errorCode)],
                        ['country', String(country)],
                    ]
                )
            );
        };

        const onClickHandler = () => {
            dispatch(setLoadingStatus(true));

            Main.customData('processor_form__click', { payment: 'paypal', event_label: 'paypal' });
        };

        const errorHandler = (e: Error) => {
            sentryCaptureEvent(e, SENTRY_PAYMENT, ERROR_LEVELS.ERROR, {
                // @ts-ignore
                ...currentProduct,
                ...meta,
            });

            dispatch(setLoadingStatus(false));
        };

        const processingHandler = () => {
            dispatch(setLoadingStatus(true));
        };

        const readyHandler = () => {
            dispatch(setLoadingStatus(false));
            dispatch(setIsLoadingPayPal(false));

            Main.customData('processor_form__load', { payment: 'paypal', event_label: 'paypal' });
        };

        dispatch(setIsLoadingPayPal(true));

        dispatch(
            initPayPalAction({
                ...currentProduct,
                ...meta,
                successHandler,
                onClickHandler,
                errorHandler,
                // @ts-ignore
                processingHandler,
                readyHandler,
            })
        );
    }

    function initPaymentGenerator() {
        const meta = {
            userId: user_id as number,
            product_code: MAIN_PLAN,
            country,
            currency: currencyName,
        };

        dispatch(setIsLoadingBankCard(true));

        dispatch(
            // @ts-ignore
            initPaymentGeneratorAction({
                ...currentProduct,
                ...meta,
            })
        );
    }

    return {
        paymentType,
        isLoading: isLoading || isLoadingPayPal || isLoadingBankCard,
        payment: {
            initPayPal,
            initPaymentGenerator,
        },
    };
};
