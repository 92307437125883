/* eslint-disable max-lines */
export const OnboardingPages = [
    {
        path: 'onboarding/AgeA',
        route: 'age-a',
    },
    {
        path: 'onboarding/CurrentBody',
        route: 'current-body-type',
    },
    {
        path: 'onboarding/CurrentWeightA',
        route: 'weight-a',
    },
    {
        path: 'onboarding/EmailConsentPageA',
        route: 'email-consent-a',
    },
    {
        path: 'onboarding/FitnessLevel',
        route: 'fitness-level',
    },
    {
        path: 'onboarding/Goal',
        route: 'goal',
    },
    {
        path: 'onboarding/GoalConf',
        route: 'goal-conf',
    },
    {
        path: 'onboarding/LoaderAConfNumbers',
        route: 'loader-a-conf',
    },
    {
        path: 'onboarding/LoaderA',
        route: 'loader-a',
    },
    {
        path: 'onboarding/SignUpB',
        route: 'email-b',
    },
    {
        path: 'onboarding/TallA',
        route: 'height-a',
    },
    {
        path: 'onboarding/TargetBody',
        route: 'target-body-type',
    },
    {
        path: 'onboarding/TargetWeightA',
        route: 'target-weight-a',
    },
    {
        path: 'onboarding/TargetZones',
        route: 'target-zones',
    },
    {
        path: 'onboarding/TargetZonesConf',
        route: 'target-zones-conf',
    },
    {
        path: 'onboarding/WorkoutLocation',
        route: 'workout-location',
    },
    {
        path: 'onboarding/WorkoutLocationConf',
        route: 'workout-location-conf',
    },
    {
        path: 'onboarding/Motivation',
        route: 'motivation',
    },
    {
        path: 'onboarding/ObSocialProof',
        route: 'ob-social-proof',
    },
    {
        path: 'onboarding/ObSocialProofB',
        route: 'ob-social-proof-b',
    },
    {
        path: 'onboarding/PushUps',
        route: 'pushups',
    },
    {
        path: 'onboarding/LifeStyle',
        route: 'lifestyle',
    },
    {
        path: 'onboarding/WalkDaily',
        route: 'walk-daily',
    },
    {
        path: 'onboarding/ObTags',
        route: 'ob-tags',
    },
    {
        path: 'onboarding/ObOccasionA',
        route: 'ob-occasion-a',
    },
    {
        path: 'onboarding/ObOccasionResult',
        route: 'ob-occasion-result',
    },
    {
        path: 'onboarding/ObOccasionResultA',
        route: 'ob-occasion-result-a',
    },
    {
        path: 'onboarding/ObOccasionResultB',
        route: 'ob-occasion-result-b',
    },
    {
        path: 'onboarding/ObTargetBody',
        route: 'ob-target-body',
    },
    {
        path: 'onboarding/ObAttitude',
        route: 'ob-attitude',
    },
    {
        path: 'onboarding/ObAttitudeA',
        route: 'ob-attitude-a',
    },
    {
        path: 'onboarding/EmailD',
        route: 'email-d',
    },
    {
        path: 'onboarding/EmailAccountA',
        route: 'email-account-a',
    },
    {
        path: 'onboarding/ObRegularActivity',
        route: 'ob-regular-activity',
    },
    {
        path: 'onboarding/ObRegularActivityA',
        route: 'ob-regular-activity-a',
    },
    {
        path: 'onboarding/ProblemZonesB',
        route: 'problem-zones-b',
    },
    {
        path: 'onboarding/ObSocialProofConf',
        route: 'ob-social-proof-conf',
    },
    {
        path: 'onboarding/EmailDConf',
        route: 'email-d-conf',
    },
    {
        path: 'onboarding/EmailConsentConf',
        route: 'email-consent-conf',
    },
    {
        path: 'onboarding/ObFamiliarityConf',
        route: 'ob-familiarity-conf',
    },
    {
        path: 'onboarding/ObActualBodyTypeExt',
        route: 'ob-actual-body-type-ext',
    },
    {
        path: 'onboarding/ObTargetBodyTypeExt',
        route: 'ob-target-body-type-ext',
    },
    {
        path: 'onboarding/ObDiet',
        route: 'ob-diet',
    },
    {
        path: 'onboarding/ObRestrictiveDiet',
        route: 'ob-restrictive-diet',
    },
    {
        path: 'onboarding/EmailEConf',
        route: 'email-e-conf',
    },
    {
        path: 'onboarding/ObWorkoutIssues',
        route: 'ob-workout-issues',
    },
    {
        path: 'onboarding/ObWorkoutIssuesFeedback',
        route: 'ob-workout-issues-feedback',
    },
    {
        path: 'onboarding/ObCommitmentDayV1',
        route: 'ob-commitment-day-v1',
    },
    {
        path: 'onboarding/ObCommitmentDayV2',
        route: 'ob-commitment-day-v2',
    },
    {
        path: 'onboarding/ObCommitmentWeek',
        route: 'ob-commitment-week',
    },
    {
        path: 'onboarding/ObCurrentBodyTypeA',
        route: 'ob-current-body-type-a',
    },
    {
        path: 'onboarding/ObTargetBodyTypeA',
        route: 'ob-target-body-type-a',
    },
    {
        path: 'onboarding/ObUserStatements',
        route: 'ob-user-statements',
    },
    {
        path: 'onboarding/ObUserStatements1',
        route: 'ob-user-statements1',
    },
    {
        path: 'onboarding/ObUserStatements2',
        route: 'ob-user-statements2',
    },
    {
        path: 'onboarding/ObUserStatements3',
        route: 'ob-user-statements3',
    },
    {
        path: 'onboarding/ObUserStatements4',
        route: 'ob-user-statements4',
    },
    {
        path: 'onboarding/ObSportExperience',
        route: 'ob-sport-experience',
    },
    {
        path: 'onboarding/ObSportExperienceFeedback',
        route: 'ob-sport-experience-feedback',
    },
    {
        path: 'onboarding/ObMeasure',
        route: 'ob-measure',
    },
    {
        path: 'onboarding/ObPace',
        route: 'ob-pace',
    },
    {
        path: 'onboarding/ObVices',
        route: 'ob-vices',
    },
    {
        path: 'onboarding/ObBodyImage',
        route: 'ob-body-image',
    },
    {
        path: 'onboarding/ObHappyWeight',
        route: 'ob-happy-weight',
    },
    {
        path: 'onboarding/ObLimitations',
        route: 'ob-limitations',
    },
    {
        path: 'onboarding/ObIssues',
        route: 'ob-issues',
    },
    {
        path: 'onboarding/ObRisks',
        route: 'ob-risks',
    },
    {
        path: 'onboarding/EmailEConfB',
        route: 'email-e-conf-b',
    },
    {
        path: 'onboarding/EmailConsentConfB',
        route: 'email-consent-conf-b',
    },
    {
        path: 'onboarding/ObFamiliarityConfB',
        route: 'ob-familiarity-conf-b',
    },
    {
        path: 'onboarding/EmailEConfCheckbox',
        route: 'email-e-conf-checkbox',
    },
    {
        path: 'onboarding/ObChallenge',
        route: 'ob-challenge',
    },
    {
        path: 'onboarding/EmailConsentConfA',
        route: 'email-consent-conf-a',
    },
    {
        path: 'onboarding/ObUserStory',
        route: 'ob-user-story',
    },
    {
        path: 'onboarding/ObUserStoryA',
        route: 'ob-user-story-a',
    },
    {
        path: 'onboarding/ObUserStoryB',
        route: 'ob-user-story-b',
    },
    {
        path: 'onboarding/ObWorkoutLocation',
        route: 'ob-workout-location',
    },
    {
        path: 'onboarding/ObWorkoutFrequency',
        route: 'ob-workout-frequency',
    },
    {
        path: 'onboarding/ObWorkoutDuration',
        route: 'ob-workout-duration',
    },
    {
        path: 'onboarding/ObStrength',
        route: 'ob-strength',
    },
    {
        path: 'onboarding/ObStrengthGym',
        route: 'ob-strength-gym',
    },
    {
        path: 'onboarding/ObEndurance',
        route: 'ob-endurance',
    },
    {
        path: 'onboarding/ObConsistency',
        route: 'ob-consistency',
    },
    {
        path: 'onboarding/ObFitnessLevelFeedback',
        route: 'ob-fitness-level-feedback',
    },
    {
        path: 'onboarding/ObResult',
        route: 'ob-result',
    },

    // exercises
    {
        path: 'onboarding/exercises/ObBarbell',
        route: 'ob-barbell-exercise',
    },
    {
        path: 'onboarding/exercises/ObDumbbell',
        route: 'ob-dumbbell-exercise',
    },
    {
        path: 'onboarding/exercises/ObCrunch',
        route: 'ob-crunch-exercise',
    },
    {
        path: 'onboarding/exercises/ObPlank',
        route: 'ob-plank-exercise',
    },
    {
        path: 'onboarding/exercises/ObDumbbellShoulder',
        route: 'ob-dumbbell-shoulder-exercise',
    },
    {
        path: 'onboarding/exercises/ObPushUp',
        route: 'ob-pushup-exercise',
    },
    {
        path: 'onboarding/exercises/ObLimbRaise',
        route: 'ob-limb-raise-exercise',
    },
    {
        path: 'onboarding/exercises/ObWalkout',
        route: 'ob-walkout-exercise',
    },
    {
        path: 'onboarding/exercises/ObReverseLunges',
        route: 'ob-reverse-lunges-exercise',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedback',
        route: 'ob-feedback',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedbackA',
        route: 'ob-feedback-a',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedbackB',
        route: 'ob-feedback-b',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedbackC',
        route: 'ob-feedback-c',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedbackD',
        route: 'ob-feedback-d',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedbackE',
        route: 'ob-feedback-e',
    },

    // overweightFlow onboarding
    {
        path: 'onboarding/overweightFlow/PerfectWeightOver',
        route: 'perfect-weight-over',
    },
    {
        path: 'onboarding/overweightFlow/NutrHabbitFeel',
        route: 'nutr-habbit-feel',
    },
    {
        path: 'onboarding/overweightFlow/ActiveHabitSleep',
        route: 'active-habit-slip',
    },
    {
        path: 'onboarding/overweightFlow/NutrHabbitWater',
        route: 'nutr-habbit-water',
    },
    {
        path: 'onboarding/ObWorkoutType',
        route: 'ob-workout-type',
    },
    {
        path: 'onboarding/ObProblemZones',
        route: 'ob-problem-zones',
    },

    // Nutrition
    {
        path: 'onboarding/ObDailyDiet',
        route: 'ob-daily-diet',
    },
    {
        path: 'onboarding/ObDietSchedule',
        route: 'ob-diet-schedule',
    },
    {
        path: 'onboarding/ObCalories',
        route: 'ob-calories',
    },
    {
        path: 'onboarding/ObFeedbackNutrition',
        route: 'ob-feedback-nutrition',
    },

    // Kegel flow
    {
        path: 'onboarding/ObGoalKgl',
        route: 'ob-goal-kgl',
    },
    {
        path: 'onboarding/ObFeedbackKglImportance',
        route: 'ob-feedback-kgl-importance',
    },
    {
        path: 'onboarding/ObKglFamiliarity',
        route: 'ob-kgl-familiarity',
    },
    {
        path: 'onboarding/ObKglLvl',
        route: 'ob-kgl-lvl',
    },
    {
        path: 'onboarding/ObKglPe',
        route: 'ob-kgl-pe',
    },
    {
        path: 'onboarding/ObKglErection',
        route: 'ob-kgl-erection',
    },
    {
        path: 'onboarding/ObKglStamina',
        route: 'ob-kgl-stamina',
    },
    {
        path: 'onboarding/ObKglFrequency',
        route: 'ob-kgl-frequency',
    },
    {
        path: 'onboarding/ObKglLibido',
        route: 'ob-kgl-libido',
    },
    {
        path: 'onboarding/ObFeedbackKglPerformance',
        route: 'ob-feedback-kgl-performance',
    },
    {
        path: 'onboarding/ObFeedbackKglLifestyle',
        route: 'ob-feedback-kgl-lifestyle',
    },
    {
        path: 'onboarding/ObFeedbackKegelHolistic',
        route: 'ob-feedback-kegel-holistic',
    },
    {
        path: 'onboarding/ObFeedbackKglLvl',
        route: 'ob-feedback-kgl-lvl',
    },
    {
        path: 'onboarding/GoalConf2',
        route: 'goal-conf-2',
    },
    {
        path: 'onboarding/EmailAccountKgl',
        route: 'email-account-kgl',
    },
    {
        path: 'onboarding/EmailEConfKgl',
        route: 'email-e-conf-kgl',
    },

    // Funnel flow
    {
        path: 'onboarding/ObGoal',
        route: 'ob-goal',
    },
    {
        path: 'onboarding/ObCurrentBodyType',
        route: 'ob-current-body-type',
    },
    {
        path: 'onboarding/ObTargetBodyA',
        route: 'ob-target-body-a',
    },
    {
        path: 'onboarding/ObMotivation',
        route: 'ob-motivation',
    },
    {
        path: 'onboarding/ObEnduranceA',
        route: 'ob-endurance-a',
    },
    {
        path: 'onboarding/ObWater',
        route: 'ob-water',
    },
    {
        path: 'onboarding/ObWorkoutLocationA',
        route: 'ob-workout-location-a',
    },
    {
        path: 'onboarding/ObOccasion',
        route: 'ob-occasion',
    },
    {
        path: 'onboarding/ObPerfectWeight',
        route: 'ob-perfect-weight',
    },
    {
        path: 'onboarding/ObWalkDaily',
        route: 'ob-walk-daily',
    },
    {
        path: 'onboarding/ObConsistencyA',
        route: 'ob-consistency-a',
    },
    {
        path: 'onboarding/ObFeedbackF',
        route: 'ob-feedback-f',
    },
    {
        path: 'onboarding/ObFeedbackH',
        route: 'ob-feedback-h',
    },
    {
        path: 'onboarding/ObFeedbackI',
        route: 'ob-feedback-i',
    },
    {
        path: 'onboarding/ObFeedbackJ',
        route: 'ob-feedback-j',
    },
    {
        path: 'onboarding/ObFeedbackG',
        route: 'ob-feedback-g',
    },
    {
        path: 'onboarding/ObStrengthA',
        route: 'ob-strength-a',
    },
    {
        path: 'onboarding/ObLifeStyle',
        route: 'ob-lifestyle',
    },
    {
        path: 'onboarding/ObProof',
        route: 'ob-proof',
    },
    {
        path: 'onboarding/ObEmailAccount',
        route: 'ob-email-account',
    },
    {
        path: 'onboarding/ObEmail',
        route: 'ob-email',
    },
    {
        path: 'onboarding/ObEmailConsent',
        route: 'ob-email-consent',
    },
    {
        path: 'onboarding/ObProblemZonesA',
        route: 'ob-problem-zones-a',
    },
    {
        path: 'onboarding/ObOccasionResultC',
        route: 'ob-occasion-result-c',
    },
    {
        path: 'onboarding/ObTagsB',
        route: 'ob-tags-b',
    },
    {
        path: 'onboarding/ObWorkoutFrequencyA',
        route: 'ob-workout-frequency-a',
    },
    {
        path: 'onboarding/ObResultA',
        route: 'ob-result-a',
    },
    {
        path: 'onboarding/ObFitnessLevelFeedbackA',
        route: 'ob-fitness-level-feedback-a',
    },
    {
        path: 'onboarding/ObSleep',
        route: 'ob-sleep',
    },
    {
        path: 'onboarding/ObHeight',
        route: 'ob-height',
    },
    {
        path: 'onboarding/ObWeight',
        route: 'ob-weight',
    },
    {
        path: 'onboarding/ObTargetWeight',
        route: 'ob-target-weight',
    },
    {
        path: 'onboarding/ObAge',
        route: 'ob-age',
    },
    {
        path: 'onboarding/ObLoader',
        route: 'ob-loader',
    },
    // AI flow
    {
        path: 'onboarding/ObFeedbackAiA',
        route: 'ob-feedback-ai-a',
    },
    {
        path: 'onboarding/ObFeedbackAiC',
        route: 'ob-feedback-ai-c',
    },
    {
        path: 'onboarding/ObFeedbackAiB',
        route: 'ob-feedback-ai-b',
    },
    {
        path: 'onboarding/EmailEConfC',
        route: 'email-e-conf-c',
    },
    {
        path: 'onboarding/ObHealthFeedback',
        route: 'ob-health-feedback',
    },
    {
        path: 'onboarding/ObHealthConditions',
        route: 'ob-health-conditions',
    },
    {
        path: 'onboarding/ObMedications',
        route: 'ob-medications',
    },
    {
        path: 'onboarding/ObHealthLoader',
        route: 'ob-health-loader',
    },
    {
        path: 'onboarding/ObInjuries',
        route: 'ob-injuries',
    },

    {
        path: 'onboarding/ObEmailCali',
        route: 'ob-email-cali',
    },
    {
        path: 'onboarding/ObEmailConsentCali',
        route: 'ob-email-consent-cali',
    },
];
