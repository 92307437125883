export const SET_GOAL = 'SET_GOAL';
export const SET_GOAL_KGL = 'SET_GOAL_KGL';
export const SET_GOAL_WIDE = 'SET_GOAL_WIDE';
export const SET_TRAINING_LOCATION = 'SET_TRAINING_LOCATION';
export const SET_CURRENT_BODY = 'SET_CURRENT_BODY';
export const SET_CURRENT_BODY_TYPE_A = 'SET_CURRENT_BODY_TYPE_A';
export const SET_TARGET_BODY = 'SET_TARGET_BODY';
export const SET_TARGET_ZONE = 'SET_TARGET_ZONE';
export const SET_ACTIVITY_LVL = 'SET_ACTIVITY_LVL';
export const SET_TARGET_WEIGHT = 'SET_TARGET_WEIGHT';
export const SET_CURRENT_WEIGHT = 'SET_CURRENT_WEIGHT';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_AGE = 'SET_AGE';
export const SET_TALL = 'SET_TALL';
export const SET_FITNESS_LVL = 'SET_FITNESS_LVL';
export const SET_PULSE_MAILING_ACTIVE = 'SET_PULSE_MAILING_ACTIVE';
export const SET_MEASURE_SYSTEM = 'SET_MEASURE_SYSTEM';
export const SET_NEXT_STEP = 'SET_NEXT_STEP';
export const INIT_AUTO_LOGIN = 'INIT_AUTO_LOGIN';
export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const SKIP_ONBOARDING = 'SKIP_ONBOARDING';
export const SET_LIFESTYLE = 'SET_LIFESTYLE';
export const SET_OCCASION = 'SET_OCCASION';
export const SET_AGE_GROUP = 'SET_AGE_GROUP';
export const SET_SPORT = 'SET_SPORT';
export const SET_IS_SKIP_OB = 'SET_IS_SKIP_OB';
export const SET_EQUIPMENT_PRESET = 'USER/SET_EQUIPMENT_PRESET';
export const SET_TRAINING_DAYS_GOAL = 'SET_TRAINING_DAYS_GOAL';
export const SET_TRAINING_DURATION = 'SET_TRAINING_DURATION';
export const SET_SCORE_RATE_STRENGTH = 'SET_SCORE_RATE_STRENGTH';
export const SET_SCORE_RATE_CONSISTENCY = 'SET_SCORE_RATE_CONSISTENCY';
export const SET_SCORE_RATE_ENDURANCE = 'SET_SCORE_RATE_ENDURANCE';
export const SET_MOTIVATION = 'SET_MOTIVATION';
export const SET_WORKOUT_ISSUE = 'SET_WORKOUT_ISSUE';
export const SET_OCCASION_DATE = 'SET_OCCASION_DATE';
export const SET_HEALTH_CONCERN = 'SET_HEALTH_CONCERN';
