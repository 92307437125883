/* eslint-disable max-lines */
import Core, { DataProvider } from 'testania';
import { ERROR_LEVELS, SENTRY_TESTANIA } from 'sentry-utils';
// eslint-disable-next-line import/named
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Severity } from '@sentry/react';

import { selectIsAutologinComplete } from 'redux/User/selectors';
import { setCountry } from 'redux/User/actions';
import { getAuthTokenAccessor } from 'redux/User/accessors';
import { showAppLoader } from 'redux/UiEffects/actions';
import { selectCompleteOBs, selectTestaniaLoginStatus, selectTestaniaName } from 'redux/Testania/selectors';
import { setOnboardingConfig, setTestaniaName } from 'redux/Testania/actions';
import { resetStore } from 'redux/rootActions';
import { selectURLParams } from 'redux/Onboadring/selectors';

import { DEFAULT_COUNTRY } from 'constants/general/countriesData';
import { DOWNLOAD_APP_PATH, TESTANIA_INIT_CONFIG, THANK_YOU_PAGE_PATH } from './constants';

import { moveToCabinet } from 'helpers/redirects';
import getAmazonDefaultData from 'helpers/analytic/getAmazonDefaultData';
import {
    clearCompleteOBsToStore,
    getIsLogin,
    getUserStatus,
    setCompleteOBsToStore,
    setCurrentBranchToStore,
    setInitMeasure,
    setTestaniaLoginStatusToStore,
    validateCountry,
} from './helpers';

import { validateFlow } from './config/flowValidation';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';

function useTestania() {
    const [startStatus, setStartStatus] = useState(false);
    const [currentFlowName, setCurrentFlowName] = useState(null);
    const testaniaLoginStatus = useSelector(selectTestaniaLoginStatus);
    const completeOBs = useSelector(selectCompleteOBs);
    const testaniaName = useSelector(selectTestaniaName);
    const history = useHistory();
    const isAutologinComplete = useSelector(selectIsAutologinComplete);
    const dispatch = useDispatch();

    const urlParams = useSelector(selectURLParams);

    const token = getAuthTokenAccessor();

    const isResetStore = localStorage.getItem('isResetStore') || null;
    const isSkipStartPage = (urlParams as Record<string, string>)?.['skipStartPage'] === 'true';
    let isFirstTimeVisit = false;

    if (isResetStore) {
        dispatch(resetStore());
    }

    const toNextPage = async (path: string): Promise<any> => {
        if (path === DOWNLOAD_APP_PATH) {
            getAmazonDefaultData().then((defaultData) => {
                moveToCabinet({ awsId: defaultData?.aws_id, uuid: defaultData?.uuid });
            });

            return false;
        }

        const redirectPrams = {
            pathname: path,
            search: history.location.search,
        };

        if (isSkipStartPage && !isFirstTimeVisit) {
            history.replace(redirectPrams);
            isFirstTimeVisit = true;
        } else {
            history.push(redirectPrams);
        }

        return true;
    };

    const initTestania = async () => {
        const responseFromTestania = await Core.init(TESTANIA_INIT_CONFIG);

        if (responseFromTestania) {
            const { country: countryFromTestania, testName } = responseFromTestania;

            const countryValue = validateCountry(countryFromTestania)
                ? countryFromTestania
                : localStorage.getItem('country') || DEFAULT_COUNTRY;

            dispatch(showAppLoader(false));

            dispatch(setCountry(countryValue));

            !token && setInitMeasure(countryValue, dispatch);

            Boolean(testName) && dispatch(setTestaniaName(testName));
        }

        responseFromTestania?.callback();
    };

    const provideData = async () => {
        await DataProvider.provideInitialData({
            loginStatus: await getIsLogin(testaniaLoginStatus, token),
            startStatus,
            startAction: async () => setStartStatus(true),
            toNextPage,
            pathTestaniaPaidFinish: THANK_YOU_PAGE_PATH,
            pathTestaniaRegistrationFinish: DOWNLOAD_APP_PATH,
            getUserStatus: () => getUserStatus(),
            getToken: async () => token,
            getCompleteOBs: async () => completeOBs,
            getCurrentBranch: async () => testaniaName,
            setCurrentBranch: async (branch: string) => setCurrentBranchToStore(branch, dispatch),
            setCompleteOBs: async (branch: string) => setCompleteOBsToStore(branch, dispatch),
            clearCompleteOBs: () => clearCompleteOBsToStore(dispatch),
            setLoginStatus: async (status: boolean) => setTestaniaLoginStatusToStore(status, dispatch),
            customFilter: (config) => validateFlow({ config, currentFlowName, setCurrentFlowName }),
            // @ts-ignore need to fix in lib, change errorLevel to Severity
            errorHandler: (error: Error, errorLevel?: Severity) =>
                sentryCaptureEvent(error, SENTRY_TESTANIA, errorLevel || ERROR_LEVELS.ERROR),
            isSkipStartPage,
        });
    };

    const initTestaniaDependencies = async () => {
        await provideData();
        await initTestania();

        const onboardingFlow = DataProvider.getOnboardingFlow();

        // @ts-ignore
        dispatch(setOnboardingConfig(onboardingFlow));
    };

    useEffect(() => {
        provideData();
    }, [token, completeOBs, testaniaName]);

    useEffect(() => {
        if (isAutologinComplete) {
            try {
                initTestaniaDependencies();
            } catch (error) {
                throw new Error(
                    `Error with init testania, check initial methods in - initTestaniaDependencies \n \n ${error}`
                );
            }
        }
    }, [isAutologinComplete]);
}

export default useTestania;
