import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { call, put, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { UPDATE_INFO_OLD } from 'redux/User/actionTypes';
import { updateUserInfoFail, updateUserInfoOld, updateUserInfoSuccess } from 'redux/User/actions';
import { initToast } from 'redux/UiEffects/actions';
import { setInvoiceInfoReceived } from 'redux/Payment/actions';

import { DEFAULT_COUNTRY } from 'constants/general/countriesData';

import { moveToCabinet } from 'helpers/redirects';

import { ISignInData } from 'types/user/auth';

import { GENERAL_ERROR_TOAST_MESSAGE } from 'modules/Toast/tosts';
import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { ISingInResponse } from 'interfaces/User/user';

export function* thankYouPageSaga({ payload }: ReturnType<typeof updateUserInfoOld>) {
    try {
        const response: ISingInResponse = yield api.user.update(payload.meta);

        if (response.status === 200) {
            yield put(updateUserInfoSuccess());

            payload.successCallback && payload.successCallback();
        }
        yield call(checkUserInvoiceInfo, response.data);

        if (response.data.is_password) {
            const { aws_id: awsId, uuid } = yield Main.getDefaultAmazonAnalyticPurchaseData();

            moveToCabinet({ awsId, uuid });
        }
    } catch (e) {
        yield put(updateUserInfoFail(e));

        yield put(initToast(GENERAL_ERROR_TOAST_MESSAGE));

        payload.errorCallback && payload.errorCallback(e?.message);

        sentryCaptureEvent(e, SENTRY_APP, ERROR_LEVELS.ERROR);
    }
}

function* checkUserInvoiceInfo(userInfo: ISignInData) {
    const isUSA = userInfo.country === DEFAULT_COUNTRY;
    const hasNameAndSurname = Boolean(userInfo.name && userInfo.surname);

    const isInvoiceInfoReceived: boolean = isUSA ? Boolean(hasNameAndSurname) : hasNameAndSurname;

    yield put(setInvoiceInfoReceived(isInvoiceInfoReceived));
}

export const handleThankYouSubmit = function* () {
    yield takeLatest(UPDATE_INFO_OLD, thankYouPageSaga);
};
