/* eslint-disable max-lines */
import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { CardBrand, ClientSdkInstance, SdkMessage } from '@solidgate/react-sdk';
import { MessageType } from '@solidgate/client-sdk-loader';

import { selectUser } from 'redux/User/selectors';
import { handleErrorAction, setPaidStatus } from 'redux/User/actions';
import {
    selectIsLoading,
    selectIsLoadingBankCard,
    selectIsLoadingPayPal,
    selectValidatePaymentData,
} from 'redux/Payment/selectors';
import {
    changePaymentMethod,
    initPaymentGenerator as initPaymentGeneratorAction,
    initPayPal as initPayPalAction,
    setExclusiveOfferHasBeenOpened,
    setHideCustomPaymentButton,
    setIsApplePayAvailable,
    setIsGooglePayAvailable,
    setIsLoadingBankCard,
    setIsLoadingPayPal,
    setIsPaymentScreen,
    setIsPaymentSuccess,
    setLoadingStatus,
    setOpenModal,
    setPaymentErrorCode,
    setPrevPaymentAttempt,
} from 'redux/Payment/actions';
import { prevPaymentAttemptAccessor } from 'redux/Payment/accessors';

import { SCENARIO_NAMES } from 'helpers/payment/CheckoutConfigurator/constants';
import {
    BANK_CARD_CUSTOM_BUTTON_ID,
    PAYMENT_EVENT_NAMES,
    PAYMENT_TYPES,
    PAYMENT_TYPES_CAPITALIZED,
    PAYPAL_OPTION,
    SOLID_ENTITY_TO_PAYMENT_METHOD_NAME_MAP,
    SOLID_TAGS,
} from 'constants/payment/payments';
import { EXCLUSIVE_OFFER_MODAL } from 'constants/payment/modals';

import useValidatePayment from 'hooks/useValidatePayment';
import useCurrencyData from 'hooks/useCurrencyData';

import {
    formatPriceRelativeToCurrency,
    getErrorReasonGroup,
    preparePayPalErrorMessage,
} from 'helpers/payment/paymentUtils';
import getErrorCustomTags from 'helpers/payment/getPaymentErrorCustomTags';
import { Attempt } from 'helpers/payment/CheckoutConfigurator/types';
import { getPrevPaymentAttempt, IPaymentData } from 'helpers/payment/CheckoutConfigurator/helpers/helpers';
import { getScenario } from 'helpers/payment/CheckoutConfigurator/checkoutScenariosConfigurator';
import { getCurrentPlatform } from 'helpers/other/getPlatform';

import { IUsePaymentLib, PayPalMeta } from './types';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { DataForValidate } from 'interfaces/Payments/paymentSystemsInit';
import usePaymentContext from '../usePaymentContext';
import useCheckoutConfig from '../useCheckoutConfig';

export const useSolidPaymentLib = ({ toNextPage, activeOption }: IUsePaymentLib) => {
    const dispatch = useDispatch();
    const cardBrandRef = useRef<CardBrand | null>(null);

    const [isProcessing, setIsProcessing] = useState(false);
    const [hasPaymentError, setHasPaymentError] = useState(false);
    const [hasPaymentSuccess, setHasPaymentSuccess] = useState(false);

    const isLoading = useSelector(selectIsLoading);
    const isLoadingPayPal = useSelector(selectIsLoadingPayPal);
    const isLoadingBankCard = useSelector(selectIsLoadingBankCard);

    const validatePaymentData = useSelector(selectValidatePaymentData);
    const { user_id, country } = useSelector(selectUser) as { user_id: number; country: string };

    const { currentProduct, pageInfo } = usePaymentContext();

    const paymentType = pageInfo.products[0].payment_type;
    const { paymentMethod: paymentMethodsConfig } = useCheckoutConfig(pageInfo?.config?.checkout);

    const prevProductId = useRef<string | null | undefined>(null);

    const { currencyName } = useCurrencyData();
    const validatePaymentHandler = useValidatePayment({ pageInfo });
    const platform = getCurrentPlatform();

    const paymentData: IPaymentData = {
        userId: user_id as number,
        country: country as string,
        currency: currencyName,
        payment_method: activeOption,
    };

    useEffect(() => {
        if (!currentProduct?.id || prevProductId.current === currentProduct.id) return;

        const displayPayPal = paymentMethodsConfig.payPal;

        initPaymentGenerator();

        if (displayPayPal) {
            initPayPal();
        }

        prevProductId.current = currentProduct.id;
    }, [currentProduct, paymentMethodsConfig.payPal]);

    useEffect(() => {
        dispatch(setIsPaymentScreen(true));

        return () => {
            dispatch(setIsPaymentScreen(false));
        };
    }, []);

    useEffect(() => {
        if (validatePaymentData && Boolean(validatePaymentData?.result)) {
            toNextPage({
                isValid: validatePaymentData.result,
            });
        }
    }, [validatePaymentData]);

    const successHandler = (data: DataForValidate, meta: PayPalMeta) => {
        const errorCode = data?.error?.code;

        if (!errorCode) {
            dispatch(setPaidStatus(true));

            dispatch(changePaymentMethod(PAYMENT_TYPES_CAPITALIZED.PAYPAL));

            validatePaymentHandler({
                paymentMethod: PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                order: {
                    amount: data.order.amount as number,
                    order_id: data.order.order_id as string,
                    subscription_id: data.order.subscription_id as string,
                },
            });

            return;
        }

        dispatch(setLoadingStatus(false));

        const prevPaymentAttempt = prevPaymentAttemptAccessor();

        const prevAttempt = getPrevPaymentAttempt(prevPaymentAttempt, paymentData, errorCode);

        dispatch(setPrevPaymentAttempt(prevAttempt));

        dispatchPaymentError(errorCode);

        handleExclusiveOffer(prevAttempt);

        initPayPal();

        dispatch(
            handleErrorAction(
                preparePayPalErrorMessage(data.error),
                SENTRY_PAYMENT,
                {
                    ...currentProduct,
                    ...meta,
                    scenarioNumber: prevPaymentAttempt?.scenarioNumber || '1',
                },
                [
                    [SOLID_TAGS.SOLID_PAYMENT_METHOD, PAYPAL_OPTION],
                    [SOLID_TAGS.SOLID_ERROR_CODE, errorCode],
                    [SOLID_TAGS.SOLID_ERROR_MESSAGE, data?.error?.recommended_message_for_user || ''],
                    [SOLID_TAGS.SOLID_ERROR_REASON, getErrorReasonGroup(errorCode)],
                    ['country', country],
                ]
            )
        );
    };

    const onClickHandler = () => {
        dispatch(setLoadingStatus(true));

        Main.customData('processor_form__click', { payment: 'paypal', event_label: 'paypal' });
    };

    const errorHandler = (e: Error, meta: PayPalMeta) => {
        sentryCaptureEvent(e, SENTRY_PAYMENT, ERROR_LEVELS.ERROR, {
            // @ts-ignore
            ...currentProduct,
            ...meta,
        });

        dispatch(setLoadingStatus(false));
    };

    const processingHandler = () => {
        dispatch(setLoadingStatus(true));
    };

    const readyHandler = () => {
        dispatch(setLoadingStatus(false));
        dispatch(setIsLoadingPayPal(false));

        Main.customData('processor_form__load', { payment: 'paypal', event_label: 'paypal' });
    };

    function initPayPal() {
        const meta: PayPalMeta = {
            userId: user_id,
            payment_method: PAYMENT_TYPES.PAYPAL,
            currency: currencyName,
            product_code: currentProduct?.product_code,
            country,
        };

        dispatch(setIsLoadingPayPal(true));

        dispatch(
            initPayPalAction({
                ...currentProduct,
                ...meta,
                successHandler: (data) => successHandler(data, meta),
                onClickHandler: () => onClickHandler(),
                errorHandler: (e) => errorHandler(e, meta),
                // @ts-ignore
                processingHandler: () => processingHandler(),
                readyHandler: () => readyHandler(),
            })
        );
    }

    function initPaymentGenerator() {
        const meta = {
            userId: user_id as number,
            product_code: currentProduct?.product_code as string,
            country,
            currency: currencyName,
        };

        dispatch(setIsLoadingBankCard(true));

        dispatch(
            // @ts-ignore
            initPaymentGeneratorAction({
                ...currentProduct,
                ...meta,
            })
        );
    }

    const showExclusiveOffer = () => {
        dispatch(setOpenModal(EXCLUSIVE_OFFER_MODAL));
        dispatch(setExclusiveOfferHasBeenOpened(true));
    };

    const handleOnSubmit = (event: SdkMessage[MessageType.Submit]) => {
        dispatch(setPaymentErrorCode(null));
        const paymentValue = PAYMENT_EVENT_NAMES[event.entity];

        dispatch(setLoadingStatus(true));
        setIsProcessing(true);

        Main.customData('processor_form__click', { payment: paymentValue, event_label: paymentValue });
    };

    const handleOnSuccess = (data: SdkMessage[MessageType.Success]) => {
        dispatch(setPaidStatus(true));

        validatePaymentHandler({
            paymentMethod: SOLID_ENTITY_TO_PAYMENT_METHOD_NAME_MAP[data.entity],
            order: {
                amount: data.order.amount as number,
                order_id: data.order.order_id as string,
                subscription_id: data.order.subscription_id as string,
            },
            ...(cardBrandRef.current && { brand: cardBrandRef.current as CardBrand }),
        });

        setHasPaymentSuccess(true);

        dispatch(setIsPaymentSuccess(true));
        dispatch(setHideCustomPaymentButton(true));

        setIsProcessing(false);
    };

    const handleCard = (e: SdkMessage[MessageType.Card]) => {
        cardBrandRef.current = e.card.brand as CardBrand;
    };

    const handleOnReadyPaymentInstance = (form: ClientSdkInstance) => {
        dispatch(setHideCustomPaymentButton(false));
        dispatch(setIsPaymentSuccess(false));
        dispatch(setLoadingStatus(false));

        setIsProcessing(false);

        const customPaymentButton = document.getElementById(BANK_CARD_CUSTOM_BUTTON_ID);

        if (customPaymentButton) {
            customPaymentButton.onclick = () => {
                if (isProcessing) return;

                form?.submit();
            };
        }

        Main.customData('processor_form__load', { payment: 'bank_card', event_label: 'bank_card' });
    };

    const handleOnError = (error: SdkMessage[MessageType.Error]) => {
        setIsProcessing(false);
        dispatch(setIsPaymentSuccess(false));

        cardBrandRef.current = null;

        const customTags = getErrorCustomTags({
            errorData: error,
            country: country as string,
            paymentMethod: activeOption,
        });
        const errorCode = error?.details?.code ?? '';

        if (errorCode) dispatchPaymentError(errorCode);

        const prevPaymentAttempt = prevPaymentAttemptAccessor();

        const prevAttempt = getPrevPaymentAttempt(prevPaymentAttempt, paymentData, errorCode);

        dispatch(
            handleErrorAction(
                error,
                SENTRY_PAYMENT,
                {
                    ...currentProduct,
                    ...paymentData,
                    scenarioNumber: prevAttempt.scenarioNumber,
                },
                customTags
            )
        );

        dispatch(setPrevPaymentAttempt(prevAttempt));

        handleExclusiveOffer(prevAttempt);
        setTimeout(() => {
            initPaymentGenerator();
        }, 5000);
    };

    const dispatchPaymentError = (errorCode: string) => {
        dispatch(
            setPaymentErrorCode({
                error_code: errorCode,
                product_name: currentProduct?.name,
                currency: paymentData.currency,
                product_price: formatPriceRelativeToCurrency(currentProduct?.price as number, paymentData.currency),
            })
        );
    };

    const handleExclusiveOffer = (prevAttempt: Attempt) => {
        const scenarioConfig = {
            platform,
            scenarioNumber: prevAttempt ? (Number(prevAttempt.scenarioNumber) + 1)?.toString() : '1',
            previousAttempt: prevAttempt,
        };
        const newScenario = getScenario(scenarioConfig);

        if (newScenario.name === SCENARIO_NAMES.EXCLUSIVE_OFFER) {
            setTimeout(() => {
                showExclusiveOffer();
            }, 2000);
        }
    };

    const handleOnFail = (error: SdkMessage[MessageType.Fail]) => {
        const customTags = getErrorCustomTags({
            errorData: error,
            country: country as string,
            paymentMethod: activeOption,
        });
        const errorCode = error?.code ?? '';

        setHasPaymentError(true);

        dispatch(setHideCustomPaymentButton(true));
        dispatch(setIsPaymentSuccess(false));

        setIsProcessing(false);

        if (errorCode) dispatchPaymentError(errorCode);

        const prevPaymentAttempt = prevPaymentAttemptAccessor();

        const prevAttempt = getPrevPaymentAttempt(prevPaymentAttempt, paymentData, errorCode);

        dispatch(
            handleErrorAction(
                error,
                SENTRY_PAYMENT,
                {
                    ...currentProduct,
                    ...paymentData,
                    scenarioNumber: prevAttempt.scenarioNumber,
                },
                customTags
            )
        );

        dispatch(setPrevPaymentAttempt(prevAttempt));

        handleExclusiveOffer(prevAttempt);

        setTimeout(() => {
            dispatch(setHideCustomPaymentButton(false));
            initPaymentGenerator();
        }, 3000);
    };

    const handleOnMounted = (event: SdkMessage[MessageType.Mounted]) => {
        if (event.type !== 'mounted') return;

        if (event.entity === 'applebtn') {
            dispatch(setIsApplePayAvailable(true));
        }

        if (event.entity === 'googlebtn') {
            dispatch(setIsGooglePayAvailable(true));
        }

        if (event.entity === 'form') {
            dispatch(setIsLoadingBankCard(false));
        }
    };

    return {
        paymentType,
        isLoading: isLoading || isLoadingPayPal || isLoadingBankCard,
        paymentData,
        isProcessing,
        handleOnSubmit,
        handleOnSuccess,
        handleOnError,
        handleOnFail,
        handleOnReadyPaymentInstance,
        handleOnMounted,
        handleCard,
        hasPaymentError,
        hasPaymentSuccess,
        setHasPaymentError,
        setHasPaymentSuccess,
    };
};
