export const StartPages = [
    {
        path: 'start/Index',
        route: 'index',
    },
    {
        path: 'start/Start',
        route: 'start',
    },
    {
        path: 'start/StartAge',
        route: 'start-age',
    },
    {
        path: 'start/StartLanding',
        route: 'start-landing',
    },
    {
        path: 'start/StartAgeFcMb',
        route: 'start-age-fcmb1',
    },
    {
        path: 'start/StartAgeConf',
        route: 'start-age-conf',
    },
    {
        path: 'start/StartAgeBranches',
        route: 'start-age-branches',
    },
    {
        path: 'start/StartAgeBranchesB',
        route: 'start-age-branches-b',
    },
    {
        path: 'start/StartOptions',
        route: 'start-options',
    },
    {
        path: 'start/StartAgeBranchesC',
        route: 'start-age-branches-c',
    },
    {
        path: 'start/StartOptionsA',
        route: 'start-options-a',
    },
    {
        path: 'start/StartAgeBranchesD',
        route: 'start-age-branches-d',
    },
    {
        path: 'start/StartPage',
        route: 'start-page',
    },
    {
        path: 'start/StartPhoto',
        route: 'start-photo',
    },
    {
        path: 'start/StartAgeBranchesE',
        route: 'start-age-branches-e',
    },
    {
        path: 'start/StartAgeBranchesF',
        route: 'start-age-branches-f',
    },
];
