import { ThemeProvider } from 'wikr-core-components';
import { compose } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { useInitApp, useThemeConfiguration } from 'hooks';
import { setThemeValues } from './hooks/useThemeConfiguration/useThemeConfiguration';
import { isRedesignedFlow } from './hooks/Testania/helpers';

import { OneTrustProvider } from './services/OneTrust';
import Routers from './router';
import { selectURLParams } from './redux/Onboadring/selectors';
import NoConnectionModal from './modals/NoConnectionModal';
import ModalManager from './modals/ModalManager';
import mirrorConfig from './mirrorConfig.json';

const App = () => {
    useInitApp();

    const themeFromConfigurator = useThemeConfiguration(mirrorConfig);
    const [theme, setTheme] = useState(themeFromConfigurator);
    const urlParams = useSelector(selectURLParams);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isRedesignedFlow(urlParams)) {
            const newTheme = setThemeValues(dispatch, mirrorConfig, 'dark');

            setTheme(newTheme);
        }
    }, [urlParams]);

    return (
        <OneTrustProvider>
            <ThemeProvider theme={theme}>
                <NoConnectionModal />
                <ModalManager />
                <Router>
                    <React.Suspense fallback={<div style={{ color: 'red' }} />}>
                        <Routers />
                    </React.Suspense>
                </Router>
            </ThemeProvider>
        </OneTrustProvider>
    );
};

export default compose(withTranslation())(App);
