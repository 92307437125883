/* eslint-disable quotes */
import { TargetZonesType } from 'types/onboarding/onboarding';

export const MUSCLE_GAIN = 'muscle_gain';
export const WEIGHT_LOSS = 'weight_loss';

export enum zones {
    arms = 'arms',
    legs = 'legs',
    pecs = 'pecs',
    belly = 'belly',
    chest = 'chest',
    back = 'back',
    full_body = 'full_body',
}

export const allZones: TargetZonesType[] = [zones.arms, zones.legs, zones.chest, zones.belly, zones.back];

export enum locations {
    home = 'home',
    gym = 'gym',
}

export enum BMIValues {
    bmiLow = 25,
    bmiNormal = 28,
    bmiOver = 31,
}

export const weeksInMonth = 4;

export const SKINNY = 'skinny';
export const BELLY = 'belly';
export const SMALL_OVERWEIGHT = 'smallOverweight';
export const AVERAGE = 'average';
export const OVERWEIGHT = 'overweight';
export const SLIGHTLY_OVERWEIGHT = 'slightly_overweight';
export const EXTRA = 'extra';
export const CUT = 'cut';
export const FIT = 'cut';
export const BULK = 'bulk';
export const EXTRA_BULK = 'extra_bulk';

export const NONE_OF_THE_ABOVE_VALUE = 'none_of_the_above';
export const NONE_OF_THIS = 'none_of_this';
export const WHOLE_BODY = 'whole_body';
export const FULL_BODY = 'full_body';
export const NONE = 'none';
export const CALISTHENICS_BRANCH = 'calisthenics';
export const HIDE_FRONT_CHAT_VALUE = '1';
export const OLD_MAN = 'old';
export const STANDARD_MAN = 'standard';
export const AI_MAN = 'AI';
export const HOME_GYM_LOCATION = 'homegym';
export enum KGL_GOAL {
    last_longer = 'last_longer',
    get_harder = 'harder_erection',
    more_intense = 'intense_orgasms',
    more_pleasure = 'more_pleasure',
    satisfy_partner = 'satisfy_partner',
}

export const KEGEL_TITLE = 'kegel';
export const PREFER_NOT_TO_SAY_OPTION = 'Prefer not to say';
export enum MotivationValues {
    improving_health = 'improving_health',
    boosting_immune = 'boosting_immune',
    looking_better = 'looking_better',
    building_strength_and_endurance = 'building_strength_and_endurance',
    boosting_libido = 'boosting_libido',
}

export const MOTIVATION_ANALYTICS_VALUES = {
    [MotivationValues.improving_health]: 'Improving health',
    [MotivationValues.boosting_immune]: 'Boosting immune system',
    [MotivationValues.looking_better]: 'Looking better',
    [MotivationValues.building_strength_and_endurance]: 'Building strength and endurance',
    [MotivationValues.boosting_libido]: 'Boosting libido',
};

export const CVD_CONCERN = 'cvd';
